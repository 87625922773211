<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <img src="../assets/logotrucker360.png" style="height: 35px" />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <!--CHeaderNavLink to="/dashboard"> Dashboard </CHeaderNavLink-->
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <!--CHeaderNavLink to="/dashboard/account" exact>
          My account
        </CHeaderNavLink-->
      </CHeaderNavItem>
    </CHeaderNav>
    <!--CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-bell"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      
    </CHeaderNav>
    <CIcon name="cifNo"/-->
    <div class="menu_lang">
      <select v-model="lang" class="form-select" @change="langChange">
        <option value="fr">Francais</option>
        <option value="en">English</option>
        <option value="es">Español</option>
      </select>
    </div>
    <div class="menu_lang">
    <CButton color="light" size="sm" @click="logout">Logout</CButton>
    </div>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
export default {
  name: "TheHeader",
   data(){
      return{
          lang: this.$i18n.locale
      }
  },
  methods: {
    async logout() {
      try {
        let r = await window.axios.post("/api/logout");
        console.log("logout", r);
        this.$store.commit("unAuth");
        this.$router.push({ path: '/pages/login' })
      } catch (error) {
        console.log(error);
      }
    },
    langChange(){
      console.log()
      this.$i18n.locale = this.lang
    },
    async testapi() {
      try {
        let r = await window.axios.get("/api/testapi");
        console.log("test api", r);
        
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.menu_lang{
  display:flex;
  align-items: center;
  margin-right: 20px;
}

select{
  border-color: #e9e0e0;
    padding-right: 18px;
    border-radius: 5px;
}

:focus {
  outline: none;
  box-shadow: none;
}
</style>