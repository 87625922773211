<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img src="../assets/Logo_BizscreenFinal_Blanc.svg" style="max-width: 48px" />
    </CSidebarBrand>

    <ul class="c-sidebar-nav h-100 ps" style="position: relative">
      <li class="c-sidebar-nav-item" data-v-afcd1a82="">
        <a href="#/dashboard" class="c-sidebar-nav-link" target="_self">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            role="img"
            class="c-sidebar-nav-icon"
          >
            <path
              fill="var(--ci-primary-color, currentColor)"
              d="M425.706,142.294A240,240,0,0,0,16,312v88H160V368H48V312c0-114.691,93.309-208,208-208s208,93.309,208,208v56H352v32H496V312A238.432,238.432,0,0,0,425.706,142.294Z"
              class="ci-primary"
            ></path>
            <rect
              width="32"
              height="32"
              x="80"
              y="264"
              fill="var(--ci-primary-color, currentColor)"
              class="ci-primary"
            ></rect>
            <rect
              width="32"
              height="32"
              x="240"
              y="128"
              fill="var(--ci-primary-color, currentColor)"
              class="ci-primary"
            ></rect>
            <rect
              width="32"
              height="32"
              x="136"
              y="168"
              fill="var(--ci-primary-color, currentColor)"
              class="ci-primary"
            ></rect>
            <rect
              width="32"
              height="32"
              x="400"
              y="264"
              fill="var(--ci-primary-color, currentColor)"
              class="ci-primary"
            ></rect>
            <path
              fill="var(--ci-primary-color, currentColor)"
              d="M297.222,335.1l69.2-144.173-28.85-13.848L268.389,321.214A64.141,64.141,0,1,0,297.222,335.1ZM256,416a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,416Z"
              class="ci-primary"
            ></path></svg
          >{{ $t("dashboard") }}
        </a>
      </li>
      <li class="c-sidebar-nav-item">
        <a
          href="#/dashboard/account"
          class="router-link-exact-active c-active c-sidebar-nav-link"
          target="_self"
          aria-current="page"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            role="img"
            class="c-sidebar-nav-icon"
          >
            <path
              fill="var(--ci-primary-color, currentColor)"
              d="M394.633,220.663,269.475,25.174a16,16,0,0,0-26.95,0L117.364,220.665A170.531,170.531,0,0,0,84.1,322.3c0,94.785,77.113,171.9,171.9,171.9s171.9-77.113,171.9-171.9A170.519,170.519,0,0,0,394.633,220.663ZM256,462.2c-77.14,0-139.9-62.758-139.9-139.9a138.758,138.758,0,0,1,27.321-83.058q.319-.432.608-.884L256,63.475,367.967,238.359q.288.453.608.884A138.754,138.754,0,0,1,395.9,322.3C395.9,399.441,333.14,462.2,256,462.2Z"
              class="ci-primary"
            ></path>
          </svg>
          {{ $t("my_account") }}
        </a>
      </li>
      <div class="ps__rail-x" style="left: 0px; bottom: 0px">
        <div
          class="ps__thumb-x"
          tabindex="0"
          style="left: 0px; width: 0px"
        ></div>
      </div>
      <div class="ps__rail-y" style="top: 0px; right: 0px">
        <div
          class="ps__thumb-y"
          tabindex="0"
          style="top: 0px; height: 0px"
        ></div>
      </div>
    </ul>

    <!--CRenderFunction flat :content-to-render="$options.nav"/-->
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from "./_nav";

export default {
  name: "TheSidebar",
  nav,
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
};
</script>
