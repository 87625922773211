<template>
  <CFooter :fixed="false">
    <div class="mx-auto">
      <!--span class="ml-1">&copy; {{new Date().getFullYear()}} Trucker&Co</span-->
      <a href="https://www.trucker360.ch/"  target="_blank">
        <img src="../assets/logotrucker360.png" style="max-width:100px;" />
      </a>

    </div>
    <!--div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://coreui.io/vue">CoreUI for Vue</a>
    </div-->
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
