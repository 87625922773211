<template>
    <div class="c-app">
        <TheSidebar/>
        <CWrapper>
            <TheHeader/>
            <div class="c-body">
                <main class="c-main">
                    <CContainer fluid>
                        <transition name="fade" mode="out-in">
                        <router-view :key="$route.path"></router-view>
                        </transition>
                    </CContainer>
                </main>
            </div>
            <TheFooter/>
        </CWrapper>
    </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
export default {
  name: 'Template',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<!--
<div id="nav">
                <router-link to="/dashboard">Home</router-link>
                <router-link to="/account" v-if="this.$store.state.user">Account of {{ this.$store.state.user.email }}</router-link> |
                <router-link to="/dashboard/about">About</router-link>
                <router-link to="/login">Login</router-link>
            </div>
        </div> 
        <div class="main">
            <router-view :key="$route.path"></router-view>
        </div>
-->